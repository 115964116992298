import React, { useEffect, useState } from 'react'
import { Card } from '@themesberg/react-bootstrap';
import { Space, Table, Button as AntButton } from 'antd'

import { getNotification } from '../../../services/apiCalls';
import { Link } from 'react-router-dom';

export default Notification =  () => {
  
  const [data, setData] = useState([]);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '10%',
      // style: {width: '10%'},
      key: 'category',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      width: '10%',
      key: 'content',
      render: text => <p dangerouslySetInnerHTML={{ __html: text }} />
    },
    {
      title: 'Date Sent',
      dataIndex: 'dateSent',
      key: 'dateSent',
    },
    {
      title: 'Initiator',
      dataIndex: 'initiator',
      key: 'initiator',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (id, record) => (
    //     <Space size="middle">
    //       <AntButton type="ghost">Edit </AntButton>
    //       {/* <AntButton type="danger" onClick={()=>confirmAction(id)}>Delete</AntButton> */}
    //     </Space>
    //   ),
    // },
  ];
  
  const getNotifications = async () => {
    const response = await getNotification()
    console.log({ ll: response })
    setData(response?.content)
    // setResponse(response)
  }

  useEffect(() => {
    getNotifications()
  }, [])
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm mt-20">
      <Card.Header>
        <h5>Notifications</h5>
        <div className='d-flex justify-content-end'>
          <Link to="/notifications/send" className="btn btn-primary">Send Notification</Link>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <Table columns={columns} pagination={false} dataSource={data} rowKey="id" />
      </Card.Body>
    </Card>
  );
};
