import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { createPushNotification, createScheduleNotification } from '../../../services/apiCalls';
import Swal from 'sweetalert2';
import { Button } from 'antd';
// import Layout from "../../components/Layout";

const PushNotification = () => {
    const [category, setCategory] = useState('NEWS');
    const [eventType, setEventType] = useState('BROADCAST');
    const [medium, setMedium] = useState('EMAIL');
    const [userType, setUserType] = useState('PERSONAL');
    const [categoryType, setCategoryType] = useState([]);
    const [messageHeader, setMessageHeader] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [eventCategory, setEventCategory] = useState('WELCOME');
    const [productType, setProductType] = useState('WAYABANK');
    const [delivery, setDelivery] = useState('INSTANT');
    const [eventDate, setEventDate] = useState('');
    const [eventTime, setEventTime] = useState('');
    const [reoccrence, setReoccrence] = useState('NONE');
    const [dayOfWeek, setDayOfWeek] = useState('MON');
    const [dayOfMonth, setDayOfMonth] = useState('1');
    const [recurrenceDays, setRecurrenceDays] = useState('MON');
    const [occationName, setOccationName] = useState('');
    const [formats, setFormats] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));

        console.log({userDetails});
        // return;
        // Handle form submission logic here
        console.log({
            category,
            eventType,
            userType,
            categoryType,
            messageHeader,
            messageBody,
            file
        });
        const postData = {
            eventType,
            medium: medium,
            category,
            data: {
                message: messageBody,
                type: messageHeader,
                "users": [
                    { "userId": "2829" },
                    { "userId": "28292" }
                ]
            },
            initiator: "Wisdom"
        }
        const data = {
            contentCategory: category,
            medium: formats,
            emailPushData: {
              eventCategory,
              subject: messageHeader,
              productType,
            },
            data: {
              header: messageHeader,
              message: messageBody,
              type: userType,
              type: "Pre-Push Message Type",
              soundBox: "true"
            },
            eventType,
            "initiator": "ADMIN",
            // "token": "string",
            userType,
            userTypeCategory: categoryType,
            "test": {
                "status": false
            }
          }
          const secData = {
            eventCategory,
            eventDate,
            userType,
            initiator: "ADMIN",
            userTypeCategory: categoryType[0],
            header: messageHeader,
            test: true,
            sendTime: eventTime,
            occationName,
            message: messageBody,
            autoSend: true,
            formats,
            productType,
            soundBox: false,
            recurrenceDays,
          }

          try {
            setLoading(true)
          let res;
            if (delivery === 'INSTANT'){
                 res = await createPushNotification(data)
            }else{
                res = await createScheduleNotification(secData)
            }

          console.log({res})
        if (res.status === 200){
            Swal.fire('Done', 'Your request is being processed');
            setMessageBody('')
            setMessageHeader('')
        }
    } catch (error) {
        console.log({error})
        Swal.fire('Error', 'An error occured, please try again');
    }finally{
        setLoading(false)
    }
    };

    const productTypes = [
        "WAYABANK",
        "WAYAQUICK",
        "WAYAGRAM",
    ]

    const categories = [
        'ALL',
        'KYC_1',
        'KYC_2',
        'KYC_3',
        'KYC_4',
        'NO_FIRST_TIME_FUNDING',
        'NO_TRANSACTIONS_IN_THE_PAST_60_DAYS_PLUS',
        'NO_TRANSACTIONS_IN_THE_PAST_30_DAYS_PLUS',
        'NO_TRANSACTIONS_IN_THE_PAST_20_DAYS_PLUS',
        'NO_BILLS_PAYMENT', 
        'NO_LOANS',
        'NO_ATM',
        'NO_POS'
    ]

    const eventCat = [
        'WELCOME',
        'DISPUTE',
        'TRANSACTION',
        'PASSWORD_RESET',
        'CREATE_PASSWORD',
        'PIN_RESET',
        'NON_WAYA',
        'LOGIN_ATTEMPT',
        'CARD_TRANSFER',
        'FUNDING',
        'WITHDRAWAL',
        'BVN',
        'CONTACT',
        'BILLS_PAYMENT',
        'TICKET',
        'PAYMENT_REQUEST',
        'ON_WAYA_PAYMENT_REQUEST',
        'ROLE_ACCESS',
        'VERIFY_OTP',
        'INVITE_TEAM',
        'REPORT_FILE',
        'KYC_REQUEST',
        'EXPORT_CARD_FILES',
        'CARD_REQUEST',
        'PRE_PRINTED_CARD_REQUEST',
        'NEWSLETTER',
        'GENERAL_ANNOUNCEMENT',
        'BIRTHDAY',
        'PASSPORT_EXPIRATION',
        'LOAN_EXPIRATION',
        'LOAN_PAYMENT',
        'LOAN_DUE_DATE',
        'SPECIAL'
    ]
    return (
        <>
        <div className='card px-5'>
            <h1>Send Notification</h1>
            <Form onSubmit={handleSubmit}>
            <Form.Group id="event" className="mb-4">
                <Form.Label>Delivery Method</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <select value={delivery} onChange={(e) => setDelivery(e.target.value)} 
                className='border' style={{ width: '100%', height: '40px', borderRadius: 5}}>
                        <option>...</option>
                        <option value="INSTANT">INSTANT</option>
                        <option value="SCHEDULE">SCHEDULE</option>
                        {/* <option value="INAPP">In APP Notification</option> */}
                    </select>
                </InputGroup>
              </Form.Group>
              {delivery === 'SCHEDULE' && 
              <>
              <Form.Group id="event" className="mb-4">
                <Form.Label>Occation Name</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <input
                    type="text"
                    className='border'
                    style={{ width: '100%', height: '40px', borderRadius: 5}}
                    onChange={(e) => setOccationName(e.target.value)}
                    value={occationName}
                />
                </InputGroup>
                </Form.Group>
              <Form.Group id="event" className="mb-4">
                <Form.Label>Schedule Date</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <input 
                    type="date" 
                    className='border' 
                    style={{ width: '100%', height: '40px', borderRadius: 5}}
                    onChange={(e) => setEventDate(e.target.value)}
                    value={eventDate}                    
                />
                </InputGroup>
                </Form.Group>
                <Form.Group id="event" className="mb-4">
                <Form.Label>Schedule Time</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <input
                    type="time"
                    className='border'
                    style={{ width: '100%', height: '40px', borderRadius: 5}}
                    onChange={(e) => setEventTime(e.target.value)}
                    value={eventTime}
                />
                </InputGroup>
                </Form.Group>
                {/* reoccrence option */}
                <Form.Group id="event" className="mb-4">
                <Form.Label>Reoccrence</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <select value={reoccrence} onChange={(e) => setReoccrence(e.target.value)}
                className='border' style={{ width: '100%', height: '40px', borderRadius: 5}}>
                        <option>...</option>
                        <option value="NONE">NONE</option>
                        <option value="ONCE">ONCE</option>
                        <option value="DAILY">DAILY</option>
                        <option value="WEEKLY">WEEKLY</option>
                        <option value="MONTHLY">MONTHLY</option>
                        {/* <option value="YEARLY">YEARLY</option> */}
                    </select>
                </InputGroup>
                </Form.Group>
                {reoccrence === 'WEEKLY' &&
                <Form.Group id="event" className="mb-4">
                <Form.Label>Day of the Week</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <select value={recurrenceDays} onChange={(e) => setRecurrenceDays(e.target.value)}
                className='border' style={{ width: '100%', height: '40px', borderRadius: 5}}>
                        <option>...</option>
                        <option value="MON">MONDAY</option>
                        <option value="TUE">TUESDAY</option>
                        <option value="WED">WEDNESDAY</option>
                        <option value="THUR">THURSDAY</option>
                        <option value="FRI">FRIDAY</option>
                        <option value="SAT">SATURDAY</option>
                        <option value="SUN">SUNDAY</option>
                    </select>
                </InputGroup>
                </Form.Group>}
                {reoccrence === 'MONTHLY' &&
                <Form.Group id="event" className="mb-4">
                <Form.Label>Day of the Month</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <select value={recurrenceDays} onChange={(e) => setRecurrenceDays(e.target.value)}
                className='border' style={{ width: '100%', height: '40px', borderRadius: 5}}>
                        <option>...</option>
                        {Array.from({length: 31}, (_, i) => i + 1).map(e=>
                        <option value={e}>{e}</option>
                        )}
                    </select>
                </InputGroup>
                </Form.Group>}      
                
                <Form.Group id="event" className="mb-4" hidden>
                <Form.Label>Format</Form.Label>
              
                <input type="checkbox" value="SMS" onChange={(e) => {
                    if (formats.includes('SMS')){
                        const newFormats = formats.filter(f=> f !== 'SMS')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> SMS {'  '}
                <input type="checkbox" value="EMAIL" onChange={(e) => {
                    if (formats.includes('EMAIL')){
                        const newFormats = formats.filter(f=> f !== 'EMAIL')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> EMAIL {''}
                <input type="checkbox" value="PUSH_NOTIFICATION" onChange={(e) => {
                    if (formats.includes('PUSH_NOTIFICATION')){
                        const newFormats = formats.filter(f=> f !== 'PUSH_NOTIFICATION')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> PUSH
                <input type="checkbox" value="IN_APP" onChange={(e) => {
                    if (formats.includes('IN_APP')){
                        const newFormats = formats.filter(f=> f !== 'IN_APP')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> IN APP NOTIFICATION
                </Form.Group>          
                        
                </>}

              <Form.Group id="event" className="mb-4">
                <Form.Label>Medium</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                {/* <select value={medium} onChange={(e) => setMedium(e.target.value)} 
                className='border' style={{ width: '100%', height: '40px', borderRadius: 5}}>
                        <option>...</option>
                        <option value="PUSH_NOTIFICATION">PUSH</option>
                        <option value="SMS">SMS</option>
                        <option value="EMAIL">EMAIL</option>
                    </select> */}
                    <input  type="checkbox" value="SMS" onChange={(e) => {
                    if (formats.includes('SMS')){
                        const newFormats = formats.filter(f=> f !== 'SMS')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> SMS {'  '}
                <input className='mx-2' type="checkbox" value="EMAIL" onChange={(e) => {
                    if (formats.includes('EMAIL')){
                        const newFormats = formats.filter(f=> f !== 'EMAIL')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> EMAIL {''}
                <input className='mx-2' type="checkbox" value="PUSH_NOTIFICATION" onChange={(e) => {
                    if (formats.includes('PUSH_NOTIFICATION')){
                        const newFormats = formats.filter(f=> f !== 'PUSH_NOTIFICATION')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> PUSH
                <input className='mx-2' type="checkbox" value="IN_APP" onChange={(e) => {
                    if (formats.includes('IN_APP')){
                        const newFormats = formats.filter(f=> f !== 'IN_APP')
                        setFormats(newFormats)
                    }
                    else{
                        setFormats([...formats, e.target.value])
                    }
                }}/> IN APP NOTIFICATION
                </InputGroup>
              </Form.Group>
            
              <Form.Group id="category" className="mb-4">
                <Form.Label>Category</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <select value={category} onChange={(e) => setCategory(e.target.value)} className='border' style={{ width: '100%', height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        <option value="NEWSLETTER">NEWS LETTER</option>
                        <option value="GENERAL_ANNOUNCEMENT">GENERAL ANNOUNCEMENT</option>
                    </select>
                </InputGroup>
              </Form.Group>
              <Form.Group id="user" className="mb-4">
                <Form.Label>User Type</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                    <select value={userType} onChange={(e) => setUserType(e.target.value)} className='border' style={{ width: '100%',height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        <option value="PERSONAL">PERSONAL</option>
                        <option value="BUSINESS">BUSINESS</option>
                    </select>
                </InputGroup>
              </Form.Group>
              <Form.Group id="user" className="mb-4">
                <Form.Label>User Category</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                    
                </InputGroup.Text> */}
                    {/* <select value={categoryType} onChange={(e) => setCategoryType(e.target.value)} className='border' style={{ width: '100%',height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        {categories.map(e=>
                        <option value={e}>{e.replace('_', ' ')}</option>
                        )}
                    </select> */}
                    {categories.map(e=>
                    <div className='d-flex mx-2'>
                    <input type="checkbox" value={e} onChange={(e) => {
                        if (categoryType.includes(e.target.value)){
                            console.log('haka')
                            const newFormats = categoryType.filter(f=> f !== e.target.value)
                            setCategoryType(newFormats)
                        }
                        else{
                            setCategoryType([...categoryType, e.target.value])
                            console.log('aa')
                        }
                        console.log(categoryType)
                    }}
                    />
                    {e.replaceAll('_', ' ')}
                    </div>
                    )}

                </InputGroup>
              </Form.Group>
              <Form.Group id="user" className="mb-4">
                <Form.Label>Event Category</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                    
                </InputGroup.Text> */}
                    <select value={eventCategory} onChange={(e) => setEventCategory(e.target.value)} className='border' style={{ width: '100%',height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        {eventCat.map(e=>
                        <option value={e}>{e.replace('_', ' ')}</option>
                        )}
                    </select>
                </InputGroup>
              </Form.Group>
              <Form.Group id="user" className="mb-4">
                <Form.Label>Product Type</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                    
                </InputGroup.Text> */}
                    <select value={productType} onChange={(e) => setProductType(e.target.value)} className='border' style={{ width: '100%',height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        {productTypes.map(e=>
                        <option value={e}>{e.replace('_', ' ')}</option>
                        )}
                    </select>
                </InputGroup>
              </Form.Group>

                <Form.Group id="subject" className="mb-4">
                    <Form.Label>Subject</Form.Label>
                    <InputGroup>
                        {/* <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text> */}
                        <Form.Control value={messageHeader} style={{ width:'80%'}} onChange={(e) => setMessageHeader(e.target.value)} name="subject" type="text" placeholder="" />
                    </InputGroup>
                </Form.Group>
                <div>
                    <label>Message Body:</label>
                    <textarea
                        className='px-2'
                        style={{ height: 200, width:'100%'}}
                        value={messageBody}
                        onChange={(e) => setMessageBody(e.target.value)} />
                </div>
                {/* <div>
                    <label>Upload File:</label>
                    <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                </div> */}
                <div style={{textAlign: 'center', marginBottom: 30 }} >
               <Button type="primary" htmlType="submit" loading={loading}>
                    Send Notification
                </Button>
                </div>
           </Form>
        </div>
        </>
    );
};

export default PushNotification;